<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

interface Props {
  id: string;
  prefix?: string;
  suffix?: string;
  description?: string;
  hasError?: boolean;
  modelValue?: string | number | null;
  modelModifiers?: {
    website?: boolean;
  };
}

const props = defineProps<Props>();

const emit = defineEmits<{
  "update:modelValue": [value: string | number];
}>();

function emitValue(e: Event) {
  let value = (e.target as HTMLInputElement).value;
  if (props.modelModifiers?.website) {
    value = value.replace(/^https?:\/\//, "");
  }

  emit("update:modelValue", value);
}
</script>

<template>
  <div
    :class="{
      'flex w-full rounded-md shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset sm:max-w-md': true,
      'ring-red-300 focus-within:ring-red-600': hasError,
      'ring-gray-300 focus-within:ring-blue-600': !hasError,
    }"
  >
    <span
      v-if="!!prefix"
      :id="`${id}-prefix`"
      class="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
      >{{ prefix }}</span
    >
    <input
      :class="{
        'block flex-1 border-0 bg-transparent py-1.5 pl-1 focus:ring-0 sm:text-sm sm:leading-6': true,
        'text-gray-900 placeholder:text-gray-400': !hasError,
        'text-red-900 placeholder:text-red-400': hasError,
        'pl-3': !prefix,
      }"
      :name="id"
      :id="id"
      :value="modelValue"
      @input="emitValue"
      v-bind="$attrs"
    />
    <span
      v-if="!!suffix"
      :id="`${id}-suffix`"
      class="flex select-none items-center pr-3 text-gray-500 sm:text-sm"
      >{{ suffix }}</span
    >
  </div>
  <p v-if="description" class="mt-1 text-sm text-gray-600">{{ description }}</p>
</template>
